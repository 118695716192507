import React from "react";
import videoBG from "../videos/videoBG.mp4";
import ritsbg from "../videos/Rits_video.mp4";
import uxbannerDark from "../Images/ritsux_bannerdark.png";

function RitsBranding() {
  return (
    <>
    <div className="hidden lg:flex my-4 gap-x-8">
      <div className="absolute left-36 top-[18.5rem]">
        <video src={ritsbg} alt="Sk Ripon web developer" className="w-1/2 lg:w-[10.5vw] mr-6 drop-shadow-md object-contain" autoPlay muted loop />
      </div>
      <video className="w-[32%] rounded-[1.25rem]" src={videoBG} autoPlay muted loop />
      <img src={uxbannerDark} alt="Sk Ripon web developer" className="w-[49vw] h-52 rounded-[1.25rem] drop-shadow-md"/>
    </div>
    <div className="mx-auto text-center">
        <h5 className="hidden md:block text-xs md:text-lg text-center font-mono font-bold text-gray-100  mt-6 lg:mt-0">
          React Js Developer | Front-end Developer | UX Designer
        </h5>
      </div>
    </>
  );
}

export default RitsBranding;
