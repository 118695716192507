import React from 'react';
import videoBG from "../videos/videoBG.mp4";
import ritsbg from "../videos/Rits_video.mp4";

function RitsVd() {
  return (
    <div>
        <div className="md:hidden">
            <div className="my-auto absolute left-36 md:left-[18rem]">
                <video src={ritsbg} alt="Sk Ripon web developer" className="w-1/2 lg:w-[10.5vw] mr-6 drop-shadow-md object-contain" autoPlay muted loop />
            </div>
            <video className="object-contain" src={videoBG} autoPlay muted loop />
            <div className="mx-auto text-center">
                <h5 className="text-xs md:text-lg text-center font-mono font-bold text-gray-100  mt-6 lg:mt-0">
                    React Js Developer | Front-end Developer | UX Designer
                </h5>
            </div>
      </div>
    </div>
  )
}

export default RitsVd