import React from "react";
import RitsBranding from "./RitsBranding";

function About() {
  const hoverEffect = () => {
    document.getElementById("aboutHeader").style.textDecoration = "underline";
  };
  const hoverNone = () => {
    document.getElementById("aboutHeader").style.textDecoration = "none";
  };

  return (
    <div
      className="w-full px-3 py-2  lg:px-10 lg:py-8 bg-gradient-to-br from-slate-800 to-[#16165e] border border-gray-700 drop-shadow-lg rounded-md transition-all ease-in duration-1000 hover:bg-gradient-to-tl "
      id="about"
      onMouseEnter={hoverEffect}
      onMouseLeave={hoverNone}
    >
      <h2
        className="text-lg font-semibold mb-2 lg:text-3xl lg:font-bold lg:mb-7 font-PlayfairD text-slate-200"
        id="aboutHeader"
      >
        About Myself
      </h2>
      <p className="text-sm mb-2 lg:text-lg text-slate-300 font-Rubik">
        Hello awesome people! Good to see you here. This page is a quick of
        summary of what I've been upto.
      </p>
      <hr className="my-1" />
      <p className="text-xs mb-2 lg:text-base text-slate-400 font-Roboto">
        <strong>
          I am a Front end web developer and a UI/UX designer who love to design
          websites and apps and give them life by coding. 
        </strong>
        &nbsp; Though I belong from medical background but a strong passion for technology and
        computers bring me into this field.
      </p>
      <p className="text-xs mb-2 lg:text-base text-slate-400 font-Roboto">
        And I believe if you have a strong reason to do something and if you are
        truely passionate about something then learning code is just a little
        thing, you can do anything, you can learn anything.
      </p>
      <p className="text-xs mb-2 lg:text-base text-slate-400 font-Roboto">
        I love to read books, listen podcast, business talks and read a lot
        about human psycology and behavior.
      </p>

      <div>
        <RitsBranding/>
      </div>
    </div>
  );
}

export default About;
