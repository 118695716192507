import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import {Outlet, Link} from "react-router-dom";
import logo from "../Images/Codelogo.png";
import frontEnd from "../Images/frontend.png";
import { FaLinkedinIn} from "react-icons/fa";
import { BsInstagram,BsGlobe, BsGithub } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { AiFillHome } from "react-icons/ai";


function Navbar() {
  const [isVisible, setIsVisible] = useState(false);
  const springProps = useSpring({ opacity: isVisible ? 1 : 0 });

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 1000);
  }, []);


  return (
    <>
     <div className='bg-[url("Images/metaverseB.jpg")] bg-no-repeat bg-cover lg:h-[90vh]'>
        <nav className='bg-[#28276b93] px-10 lg:px-32 text-white items-center flex justify-between sticky z-20 top-0 opacity-90 shadow-lg backdrop-blur-md border-b border-slate-600'>
          <div className='flex items-center py-2'>
            <img src={logo} alt="logo" className='w-8 mx-1 md:w-10 md:mx-3'/>
            <span className='font-Itim font-semibold md:text-lg lg:text-2xl'>Code With Rits</span>
          </div>
          <div><Link to={"/"} className="md:hidden lg:hidden text-xl flex items-center font-Itim gap-x-1 active:text-pink-500 hover:text-pink-100"><AiFillHome/></Link></div>
          <div className='hidden md:flex lg:flex justify-around items-center md:gap-x-3 lg:gap-x-6'>
            <Link to={"/"} className="border-r-2 border-white pr-8 text-xl flex items-center font-Itim gap-x-1 active:text-pink-500 hover:text-pink-100"><AiFillHome/>Home</Link>
            <a href="https://www.linkedin.com/in/sk-ripon-a82a7b222/" target={"_blank"} rel="noreferrer" className='text-xl hover:text-pink-500'><FaLinkedinIn/></a>
            <a href="https://www.instagram.com/ritsux.coder/" target={"_blank"} rel="noreferrer" className='text-xl hover:text-pink-500'><BsInstagram/></a>
            <a href="https://github.com/myselfripon20/" target={"_blank"} rel="noreferrer" className='text-xl hover:text-pink-500'><BsGithub/></a>
            <a href="https://skriponux.pages.dev/" target={"_blank"} rel="noreferrer" className='text-xl hover:text-pink-500'><BsGlobe/></a>
            <a href="mailto:myselfripon20@gmail.com" target={"_blank"} rel="noreferrer" className='text-xl hover:text-pink-500'><GrMail/></a>
          </div>
        </nav>
                             {/* Hero Section  */}
        <animated.div style={springProps} className='px-12 mt-20 md:mt-32 lg:mt-48 lg:px-32'>
          <h1 className='text-white text-4xl lg:text-8xl font-PlayfairD font-semibold my-4'>Hi! <br /> I am Sk Ripon</h1>
          <h1 className='text-gray-300 text-xl lg:text-4xl font-PlayfairD font-semibold'>A Front-end Web Developer</h1>
          <div>
            <img src={frontEnd} alt="Front-end web developer" className='lg:w-1/2 mt-5 lg:mt-0'/>
          </div>
        </animated.div>
     </div>
      <Outlet />
    </>
  )
}

export default Navbar;


