import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Resume from './components/Resume';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route index element={<Home/>} />
            <Route path='/resume' element={<Resume/>} />
            <Route path='/privacy' element={<PrivacyPolicy/>} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
