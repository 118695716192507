import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import About from '../components/About';
import Footer from '../components/Footer';
import Projects from '../components/Projects';
import RitsVd from '../components/RitsVd';
import ViewResume from '../components/ViewResume';

function Home() {
  const [isVisible, setIsVisible] = useState(false);
  const springProps = useSpring({ opacity: isVisible ? 1 : 0 });

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2000);
  }, []);


  return (
    <>
      <div className='lg:px-32 bg-[#100028]'>
        <animated.div style={springProps} className='py-4 mx-5 lg:py-6 lg:mx-0 ease-in transition-all'>
            <ViewResume/>
        </animated.div>
        <animated.div style={springProps} className='py-4 mx-5 lg:pb-6 lg:mx-0 ease-in transition-all'> 
          <About/>
        </animated.div>
        <animated.div style={springProps} className='py-4 mx-5 lg:pb-6 lg:mx-0 ease-in transition-all'> 
        <RitsVd/>
        </animated.div>
        <animated.div style={springProps} className='py-4 mx-5 lg:pb-6 lg:mx-0 ease-in transition-all'>
          <Projects/>
        </animated.div>
      </div>
      <Footer/>
    </>
  )
}

export default Home;