import React from "react";
import {Outlet, Link} from "react-router-dom";
import ResumePic from "../Images/ViewResume.jpg";
import Download from "../Images/download.jpg";
import { FaDownload } from "react-icons/fa";

function ViewResume() {
  // Function will execute on click of button
  const onButtonClick = () => {
    const fileURL = process.env.PUBLIC_URL + '/skripon_reactdeveloper.pdf';
    window.location.assign(fileURL);
  }

  return (
    <>
    <div className="p-2 rounded-lg shadow-md flex flex-col lg:flex-row lg:justify-between"> 
      <Link to={"/resume"} class="flex flex-col items-center bg-slate-800 border border-gray-700 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-slate-700">
          <img class="w-full rounded-t-lg h-76 md:h-auto md:w-48 lg:w-64 md:rounded-none md:rounded-l-lg" src={ResumePic} alt=""/>
          <div class="flex flex-col justify-between p-4 leading-normal">
              <h5 class="mb-2 text-lg lg:text-2xl font-semibold font-Roboto tracking-tight text-gray-300">VIEW MY FULL RESUME</h5>
              <p class="mb-3 font-normal font-Roboto text-gray-400">For Professional who want to hire me for job or any freelance projects click below to see my full resume.</p>
          </div>
      </Link>
      <div class="flex flex-col mt-6 lg:mt-0 items-center bg-slate-800 border border-gray-700 rounded-lg shadow md:flex-row md:max-w-xl">
          <img class=" w-full rounded-t-lg h-fit md:h-auto md:w-48 lg:w-72 md:rounded-none md:rounded-l-lg" src={Download} alt=""/>
          <div class="flex flex-col justify-between p-4 leading-normal">
              <h5 class="lg:mb-2 text-xl lg:text-2xl font-semibold font-Roboto tracking-tight text-gray-300 ">DOWNLOAD RESUME</h5>
              <button onClick={onButtonClick} class="text-2xl relative inline-flex items-center justify-center py-3 mt-6 mb-2 mr-2 overflow-hidden font-medium rounded-md text-slate-200 border border-slate-400 hover:bg-slate-600 hover:text-slate-100 focus:border focus:border-white active:bg-purple-600">
                <FaDownload/>
              </button>
          </div>
      </div>
    </div>
    <Outlet/>
    </>
  );
}

export default ViewResume;


// For Professional who want to hire me for job or any freelance projects
// click below to see my full resume.