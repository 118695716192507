import React from 'react';
import img1 from "../Images/Codewithrits.png"
import img2 from "../Images/Ritsux.png"
import img3 from "../Images/Gymbros.png"
import img4 from "../Images/Hoobank.png"
import img5 from "../Images/Happysmile.png"
import img6 from "../Images/Project (5).jpg"

function Projects() {
  let img = `p-2 lg:p-0 lg:w-1/3 hover:rotate-6 cursor-pointer`;
  return (
    <div className='w-full py-4'>
        <h2 className='text-2xl lg:text-3xl font-PlayfairD text-slate-100 font-bold text-center rounded-sm border border-slate-500 px-4 py-2 mb-8 bg-slate-700'>Projects</h2>
        <div className='my-3 flex flex-wrap gap-5 lg:gap-x-6 lg:gap-y-8 justify-center'>
            <a className={img} href="/"><img  src={img1} alt="Web Development projects - codewithrits" /></a>
            <a className={img} href="http://skriponux.com" target="_blank" rel="noopener noreferrer"><img src={img2} alt="Web Development projects - codewithrits" /></a>
            <a className={img} href="http://gymbros.pages.dev" target="_blank" rel="noopener noreferrer"><img src={img3} alt="Web Development projects - codewithrits" /></a>
            <a className={img} href="http://web3-hoobank.pages.dev" target="_blank" rel="noopener noreferrer"><img src={img4} alt="Web Development projects - codewithrits" /></a>
            <a className={img} href="http://happysmile.pages.dev" target="_blank" rel="noopener noreferrer"><img src={img5} alt="Web Development projects - codewithrits" /></a>
            <a className={img} href="http://skriponux.com/webdevelopment/" target="_blank" rel="noopener noreferrer"><img src={img6} alt="Web Development projects - codewithrits" /></a>
        </div>
    </div>
  )
}

export default Projects